<template>
	<div style="background: #fff;padding: 15px;" class="widthDrawal-box">
		<!-- tab切换 -->
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id + ''"></el-tab-pane>
		</el-tabs>

		<div v-if="listState == 0">
			<div class="filter-container">
				<div class="filter-item">
					<label class="label" style="white-space: nowrap;font-weight: 700;">关键字:</label>
					<el-input v-model="searchKey" placeholder="姓名、微信昵称、手机号" style="width: 300px;"></el-input>
				</div>
				<div class="filter-item">
					<label class="label" style="margin-left: 40px;white-space: nowrap;">申请时间:</label>
					<el-date-picker v-model="startTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
					 :picker-options="maxOptions" placeholder="开始时间"></el-date-picker>
					<span style="padding: 0 10px;">~</span>
					<el-date-picker v-model="endTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
					 :picker-options="minOptions" placeholder="结束时间"></el-date-picker>
				</div>
			</div>
			<div class="filter-container">
				<div class="filter-item">
					<label class="label" style="white-space: nowrap;">申请人:</label>
					<el-select v-model="authType" clearable style="margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="(item, index) in authList" :key="index" :label="item.type" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">

					<label class="label" style="white-space: nowrap;margin-left: 20px;">账户类型:</label>
					<el-select v-model="accountType" clearable style="margin-right: 10px;">
						<el-option v-for="(item, index) in accountTypeList" :key="index" :label="item.value" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:30px;width: 90px;" size="small" @click="goodsSearch">查询</el-button>
					<button-permissions :datas="'withDrawalExport'">
						<el-button style="margin-left:20px;width: 90px;" size="small" @click="exportFun">导出</el-button>
					</button-permissions>
				</div>
			</div>

			<div class="warn_Content displayCommon" v-if='accountType==0&&!IsopenApplyPay'>
				<svg-icon icon-class="warn" style="margin-right: 10px;" />
				<div class="warin_right">
					<span>温馨提示：您的商城当前还未绑定企业支付宝账号，客户或店员申请提现支付宝时，无法通过企业支付宝自动转账，因此审核通过前请确认已线下转账成功。</span>
					<el-link href="https://jusnn6k8al.feishu.cn/docx/HqiwdsEWBopmqAxlBNPczUbenYe" target="_blank">
						<span style="margin-left: 10px;color: #0000CC;cursor: pointer;">如何开通企业支付宝自动转账?</span>
					</el-link>
				</div>
			</div>

			<!--  -->
			<div class="table-container" style="margin-top: 10px;">
				<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" :key="99"></el-table-column>
					<el-table-column prop="ApplicantTypeValue" label="申请人" :key="1"></el-table-column>
					<el-table-column label="姓名" :key="2">
						<template slot-scope="scope">
							<span v-if="scope.row.Name==''">{{scope.row.WxNickname}}</span>
							<span v-else>{{scope.row.Name}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="WithdrawalMoney" label="提现金额" :key="3"></el-table-column>
					<el-table-column prop="AlipayAccount" label="提现账号" :key="4">
						<template slot-scope="scope">
							<span v-if="scope.row.WithdrawalsAccountType==0">{{scope.row.AlipayAccount}}</span>
							<div v-else>
								<div>{{scope.row.BankCardNo}}</div>
								<div>{{scope.row.BankName}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AlipayName" label="账户姓名" :key="5">
						<template slot-scope="scope">
							<div v-if="scope.row.WithdrawalsAccountType==0">{{scope.row.AlipayName}}</div>
							<div v-if="scope.row.WithdrawalsAccountType==1">{{scope.row.BankCardHolder}}</div>
							<div>{{scope.row.Phone}}</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="Phone" label="联系电话" :key="6"></el-table-column> -->
					<el-table-column prop="AddTime" label="申请时间" :key="7"></el-table-column>
					<el-table-column label="备注" :key="8" width="250px">
						<template slot-scope="scope">
							<div style="color: #F56C6C;">{{ scope.row.Remark }}</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="250" :key="9">
						<template slot-scope="scope">
							<button-permissions :datas="'passCash'">
								<el-button type="text" @click="verifyOk(scope.row)">审核通过</el-button>
							</button-permissions>

							<button-permissions :datas="'failCash'" style="margin:0 10px;">
								<el-button type="text" style="color:#F56C6C;" @click="verifyRefused(scope.row)">审核否决</el-button>
							</button-permissions>
							<!-- <span style="color:#F56C6C;margin-left:10px;margin-right: 10px;" @click="verifyRefused">审核否决</span> -->
							<!-- <el-button type="text" @click="showMore(scope.row)">更多</el-button> -->

							<button-permissions :datas="'editccount'">
								<el-dropdown trigger="click">
									<span class="el-dropdown-link">更多</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item>
											<div @click="changeAccount(scope.row)">修改提现账号</div>
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</button-permissions>

						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;" v-if="Total">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						<el-checkbox @change="tabCheckedChange" v-model="checked">当前页全选</el-checkbox>

						<el-dropdown size="mini" style="margin-left: 15px;">
							<el-button size="mini">批量通过</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<div @click="batchPast(1)">批量通过当前页选中的申请</div>
								</el-dropdown-item>
								<!-- <el-dropdown-item>
									<div @click="batchPast(2)">批量通过全部待审核的申请</div>
								</el-dropdown-item> -->
							</el-dropdown-menu>
						</el-dropdown>

						<el-dropdown size="mini" style="margin-left: 15px;">
							<el-button size="mini">批量否决</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<div @click="batchRefused(1)">批量否决当前页选中的申请</div>
								</el-dropdown-item>
								<!-- <el-dropdown-item>
									<div @click="batchRefused(2)">批量否决全部待审核的申请</div>
								</el-dropdown-item> -->
							</el-dropdown-menu>
						</el-dropdown>
						<!-- <span style="margin-left: 20px;border: 1px solid #E5E9F2;padding: 5px 10px;" @click="batchPast">批量通过</span>
						<span style="margin-left: 10px;border: 1px solid #E5E9F2;padding: 5px 10px;" @click="batchRefused">批量否决</span> -->
					</div>
					<el-pagination style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total"></el-pagination>
				</div>
			</div>
		</div>

		<!--  -->

		<div v-if="listState == 1">
			<div class="filter-container">
				<div class="filter-item">
					<div style="display: flex;align-items: center;">
						<div class="label" style="white-space: nowrap;font-weight: 700;">关键字:</div>
						<el-input v-model="searchKey" placeholder="姓名、微信昵称、手机号" style="width: 300px;"></el-input>
						<label class="label" style="margin-left: 40px;white-space: nowrap;">申请时间:</label>
						<div style="display: flex;align-items: center;">
							<el-date-picker v-model="startTime2" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
							 :picker-options="maxOptions2" placeholder="开始时间"></el-date-picker>
							<span style="margin: 0 10px;">~</span>
							<el-date-picker v-model="endTime2" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
							 :picker-options="minOptions2" placeholder="结束时间"></el-date-picker>
						</div>
					</div>
				</div>
			</div>
			<div class="filter-container">
				<div class="filter-item">
					<label class="label" style="white-space: nowrap;font-weight: 700;">申请人:</label>
					<el-select v-model="activeType" clearable style="margin-right: 20px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="(item, index) in authList" :key="index" :label="item.type" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label" style="white-space: nowrap;font-weight: 700;">提现状态:</label>
					<el-select v-model="drawType" clearable style="margin-right: 20px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="(item, index) in drawList" :key="index" :label="item.type" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label" style="white-space: nowrap;margin-left: 20px;">账户类型:</label>
					<el-select v-model="accountType" clearable style="margin-right: 10px;">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="(item, index) in accountTypeList" :key="index" :label="item.value" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:20px;width:90px;" size="small" @click="goodsFilter">查询</el-button>
					<button-permissions :datas="'withDrawalrecorExport'">
						<el-button style="margin-left:20px;width: 90px;" size="small" @click="exportFun1">导出</el-button>
					</button-permissions>
				</div>

			</div>

			<div class="table-container">
				<el-table :data="groupData2" style="width: 100%;" v-loading="loading">
					<el-table-column prop="ApplicantTypeValue" label="申请人" :key="17"></el-table-column>
					<el-table-column label="姓名" :key="10">
						<template slot-scope="scope">
							<span v-if="scope.row.Name==''">{{scope.row.WxNickname}}</span>
							<span v-else>{{scope.row.Name}}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="Name" label="姓名" :key="10"></el-table-column> -->
					<el-table-column prop="WithdrawalMoney" label="提现金额" :key="11"></el-table-column>
					<el-table-column prop="WithdrawalStateValue" label="提现状态" :key="18"></el-table-column>
					<el-table-column prop="AlipayAccount" label="提现账号" :key="12">
						<template slot-scope="scope">
							<span v-if="scope.row.WithdrawalsAccountType==0">{{scope.row.AlipayAccount}}</span>
							<div v-else>
								<div>{{scope.row.BankCardNo}}</div>
								<div>{{scope.row.BankName}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AlipayName" label="账户姓名" :key="13">
						<template slot-scope="scope">
							<div v-if="scope.row.WithdrawalsAccountType==0">{{scope.row.AlipayName}}</div>
							<div v-if="scope.row.WithdrawalsAccountType==1">{{scope.row.BankCardHolder}}</div>
							<div>{{scope.row.Phone}}</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="Phone" label="联系电话" :key="14"></el-table-column> -->
					<el-table-column prop="AddTime" label="申请时间" :key="15"></el-table-column>
					<el-table-column prop="CheckTime" label="审核时间" :key="19"></el-table-column>
					<el-table-column prop="Remark" label="备注" :key="16" width="300px"></el-table-column>
				</el-table>

				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
					<el-pagination v-if="Total1" style="margin-top:20px;float:right;" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
					 :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="20" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total1"></el-pagination>
				</div>
			</div>
		</div>
		<!-- 提示弹窗 -->
		<el-dialog title="请注意" :visible.sync="verifyDialogShow" width="500px" :show-close="false" v-loading="dialogloading">
			<!-- //开启企业支付宝 -->
			<div style="line-height: 25px;" v-if='IsopenApplyPay'>
				是否确认审核通过{{ refusedRow.Name }}的提现申请？审核通过后，将通过企业支付宝自动转账{{ refusedRow.WithdrawalMoney }}元至{{ refusedRow.Name }}的支付宝账户
			</div>
			<!-- //未开启企业支付宝支付 -->
			<div style="line-height: 25px;" v-if='!IsopenApplyPay'>
				您正在操作审核通过{{ refusedRow.Name }}的提现申请，提现金额{{ refusedRow.WithdrawalMoney }}元，提现至<span style="color: red;">支付宝</span>。审核通过操作不可撤销，<span
				 style="color: red;">请确认您已成功转账</span>。
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="verifyDialogShow = false">关闭</el-button>
				<el-button style="margin-left: 30px;" type="primary" @click="verifySuccess()">{{IsopenApplyPay?'确认通过':'确认已转账并审核通过'}}</el-button>
			</span>
		</el-dialog>

		<!-- 批量提示弹窗 -->
		<el-dialog :visible.sync="allverifyDialogShow" width="500px" :show-close="false" title="请注意" v-loading="dialogloading">
			<div style="line-height: 25px;" v-if='IsopenApplyPay'>是否确认批量审核通过{{ selectionLength }}条提现申请？审核通过后，将通过企业支付宝自动转账申请人的支付宝账户</div>
			<div style="line-height: 25px;" v-if='!IsopenApplyPay'>
				您正在操作批量审核通过{{ selectionLength }}条提现申请，提现至<span style="color: red;">支付宝</span>。审核通过操作不可撤销，<span
				 style="color: red;">请确认您已成功转账</span>。
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="allverifyDialogShow = false">关闭</el-button>
				<el-button style="margin-left: 30px;" type="primary" @click="batchSure()">{{IsopenApplyPay?'确认通过':'确认已转账并审核通过'}}</el-button>
			</span>
		</el-dialog>

		<!-- 单个审核通过失败提示弹窗 -->
		<el-dialog :visible.sync="failDialogShow" width="500px" center :show-close="false">
			<div style="line-height: 25px;">审核通过失败。失败原因：{{failReason}}</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="closePop">关闭</el-button>
			</span>
		</el-dialog>

		<!-- 审核否决提示弹窗 -->
		<el-dialog :visible.sync="refusedDialogShow" width="500px" center :show-close="false" title="审核否决" v-loading="dialogloading">
			<el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="80px" class="demo-ruleForm">
				<el-form-item label="否决原因" prop="refusedReason">
					<el-input type="textarea" :rows="4" v-model="ruleForm2.refusedReason"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="authRefused('ruleForm2')">关闭</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="authSuccess('ruleForm2')">确认否决</el-button>
			</span>
		</el-dialog>

		<!-- 批量打款失败提示弹窗 -->
		<el-dialog :visible.sync="allFailDialogShow" width="500px" center :show-close="false">
			<div style="line-height: 25px;">批量审核通过结果：打款成功80条；打款失败10条，部分提现申请状态已更新。刷新页面查看最新数据</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="refreshData">刷新</el-button>
			</span>
		</el-dialog>

		<!-- 修改提现账号提示弹窗 -->
		<el-dialog :visible.sync="editInfoDialogShow" width="650px" center :show-close="false" title="修改提现账号" @close="inforCloseFun">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" v-loading="blankLoadings" class="demo-ruleForm"
			 style="width: 100%;">
				<div v-show="isShowBankstyle">
					<el-form-item label="请选择账户类型:">
						<el-radio v-model="ruleForm.accountStyle" @change="styleChange" :label="0">提现至支付宝</el-radio>
						<el-radio v-model="ruleForm.accountStyle" @change="styleChange" :label="1">提现至银行卡</el-radio>
					</el-form-item>
				</div>
				<div v-if="ruleForm.accountStyle==0&&(IsOpenWithdrawalsToAlipay||(MallEmployeeId>0))">
					<el-form-item label="支付宝账号:" :prop="ruleForm.accountStyle==0?'account':'nocheck'" :rules='ruleForm.accountStyle==0?rules.account:rules.nocheck'>
						<el-input v-model="ruleForm.account" placeholder="请输入支付宝账号" style="width: 450px;"></el-input>
					</el-form-item>
					<el-form-item label="账号姓名:" :prop="ruleForm.accountStyle==0?'name':'nocheck'" :rules='ruleForm.accountStyle==0?rules.name:rules.nocheck'>
						<el-input v-model="ruleForm.name" placeholder="请输入支付宝账户姓名，若姓名与账号不符，将会打款失败" style="width: 450px;"></el-input>
					</el-form-item>
				</div>

				<div v-if="ruleForm.accountStyle==1&&(IsOpenWithdrawalsToBankCard)">
					<el-form-item label="开户行:" :prop="ruleForm.accountStyle==1?'accountBank':'nocheck'" :rules='ruleForm.accountStyle==1?rules.accountBank:rules.nocheck'>
						<el-select v-model="ruleForm.accountBank" clearable style="width: 350px;">
							<el-option placeholder="请选择开户银行" v-for="(item, index) in accountBankList" :key="index" :label="item.BankName"
							 clearable filterable :value="item.Id"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="银行卡账号" :prop="ruleForm.accountStyle==1?'numberBank':'nocheck'" :rules='ruleForm.accountStyle==1?rules.numberBank:rules.nocheck'>
						<el-input placeholder="请输入银行卡账号" v-model="ruleForm.numberBank" clearable onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
						 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" onblur="this.v();this.v();"
						 style="width:350px">
						</el-input>
					</el-form-item>

					<el-form-item label="账户姓名" :prop="ruleForm.accountStyle==1?'bankName':'nocheck'" :rules='ruleForm.accountStyle==1?rules.bankName:rules.nocheck'>
						<el-input style="width:350px" :class="{'nameRedcolor':isredColor&&ruleForm.accountStyle==1&&IsOpenBankElementsAttestation}"
						 :disabled="ruleForm.accountStyle==1&&IsOpenBankElementsAttestation" v-model="ruleForm.bankName" maxlength="20"></el-input>
					</el-form-item>

				</div>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="editInfoDialogShow = false">关闭</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="changeAcountSure('ruleForm')" v-loading="sureEditeing">{{sureHtmls}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	import {
		mallDataInfo
	} from '@/api/TurnTomySelf.js';
	import {
		WithdrawalList,
		WithdrawalcheckSuccess,
		WithdrawalcheckFail,
		WithdrawalupdateAccount
	} from '@/api/wyUsedInterface.js';
	import {
		commissionWithdrawalList,
		commissionWithdrawalAccount,
		commissionWithdrawalInt
	} from '@/api/goods.js';

	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			return {
				//是否开通支付宝
				IsopenApplyPay: true,
				accountBankList: [],
				ruleForm: {
					account: '',
					name: '',
					accountStyle: 0,
					accountBank: '',
					numberBank: '',
					bankName: ''
				},
				ruleForm2: {
					refusedReason: ''
				},
				rules2: {
					refusedReason: [{
							required: true,
							message: '请输入审核否决原因',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 300,
							message: '否决原因最多可输入300字',
							trigger: 'blur'
						}
					]
				},
				rules: {
					account: [{
						required: true,
						message: '请输入支付宝账号',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入支付宝账户姓名，若姓名与账号不符，将会打款失败',
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					accountBank: [{
						required: true,
						message: '请选择开户银行',
						trigger: 'change'
					}],
					numberBank: [{
						required: true,
						message: '请输入银行卡账号',
						trigger: 'blur'
					}],
					bankName: [{
						required: true,
						message: '请输入账户姓名',
						trigger: 'blur'
					}],
				},
				listStateList: [{
						id: 0,
						value: '待审核'
					},
					{
						id: 1,
						value: '提现记录'
					}
				],
				listState: '0',
				groupData: [],
				groupData2: [],
				authList: [{
						id: 1,
						type: '员工'
					},
					{
						id: 2,
						type: '客户'
					}
				],
				drawList: [{
						id: 0,
						type: '待审核'
					},
					{
						id: 1,
						type: '提现成功'
					},
					{
						id: 2,
						type: '提现失败'
					}
				],
				searchKey: '',
				loading: false,
				editVisable: false,
				groupName: '',
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				authType: 2, //待提现的申请人
				activeType: null, //提现记录的申请人
				drawType: null,
				timeValue: '',
				timeValue2: '',
				checked: false,
				multipleSelection: [],
				isCurrentAll: false,
				verifyDialogShow: false,
				allverifyDialogShow: false,
				failDialogShow: false, //单个审核通过失败的弹窗
				refusedDialogShow: false, //审核否决弹窗
				allFailDialogShow: false, //批量打款失败提示弹窗
				editInfoDialogShow: false, //修改提现账号弹窗
				endTime: '',
				startTime: '',
				endTime2: '',
				startTime2: '',
				checkSuccessList: [],
				refusedRow: {},
				isBatchRefused: false, //是否是批量否决
				failReason: '',
				sureEditeing: false,
				WithdrawalResult: {},
				accountType: 0,
				accountTypeList: [{
						id: 0,
						value: '支付宝'
					},
					{
						id: 1,
						value: '银行卡'
					}
				],
				exportUrl: config.EXPORT_URL,
				IsOpenWithdrawalsToAlipay: false,
				IsOpenWithdrawalsToBankCard: false,
				isShowBankstyle: false,
				withDrawType: '',
				selectionLength: 0,
				MallEmployeeId: '',
				bankInformations: {},
				IsOpenBankElementsAttestation: false,
				isredColor: false,
				blankLoadings: false,
				BankCardHolder: '',
				sureHtmls: '确认修改',
				dialogloading:false
			};
		},
		beforeMount() {
		},
		created() {
			this.listState = this.$route.query.listState?this.$route.query.listState:'0'
			this.searchKey = this.$route.query.searchKey?this.$route.query.searchKey:''
			this.getMallInfo()
			this.withdrawalInt()
			if(this.listState==0){
				this.getAuthData();
			}else{
				this.getDrawData();
			}
		},
		methods: {
			// 获取商户支付信息
			async getMallInfo() {
				try {
					let result = await mallDataInfo()
					this.IsOpenWithdrawalsToAlipay = result.Result.IsOpenWithdrawalsToAlipay 
					this.IsOpenWithdrawalsToBankCard = result.Result.IsOpenWithdrawalsToBankCard  

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {

				}

			},
			openApply() {
				window.open('https://shimo.im/docs/XXvtvpThv3VDdKhP/')
			},
			//顶部tab切换事件
			filterFun(tab, event) {
				this.accountType = 0
				if (tab.index == 0) {
					this.getAuthData();
				} else if (tab.index == 1) {
					this.getDrawData();
				}
			},
			// 获取银行卡列表
			async getBankList() {
				try {
					let result = await commissionWithdrawalList()
					this.accountBankList = result.Result

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {

				}

			},
			// 待审核导出
			async exportFun() {
				try {
					let url = this.exportUrl + '/pc/commissionWithdrawal/list?' +
						'&IsWaitCheckTab=' + true +
						'&Keywords=' + this.searchKey +
						'&WithdrawalsAccountType=' + this.accountType +
						'&StartTime=' + (this.startTime ? this.startTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&ApplicantType=' + (this.activeType ? this.activeType : '')

					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			// 提现记录导出
			async exportFun1() {
				this.loading = true;

				try {
					let url = this.exportUrl + '/pc/commissionWithdrawal/list?' +
						'&IsWaitCheckTab=' + false +
						'&Keywords=' + this.searchKey +
						'&WithdrawalsAccountType=' + ((this.accountType == null && this.accountType != 0) ? '' : this.accountType) +
						'&StartTime=' + (this.startTime2 ? this.startTime2 : '') +
						'&EndTime=' + (this.endTime2 ? this.endTime2 : '') +
						'&ApplicantType=' + (this.activeType ? this.activeType : '') +
						'&WithdrawalState=' + (this.drawType ? this.drawType : '')

					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},

			async getDrawData() {
				//提现记录
				try {
					this.loading = true
					let data = {
						IsWaitCheckTab: false,
						Keywords: this.searchKey,
						StartTime: this.startTime2,
						EndTime: this.endTime2,
						ApplicantType: this.activeType,
						WithdrawalState: this.drawType,
						WithdrawalsAccountType: this.accountType,
						Skip: (this.currentPage1 - 1) * this.pageSize1,
						Take: this.pageSize1
					};
					let result = await WithdrawalList(data);
					this.groupData2 = result.Result.Results
					console.log("this.groupData2",this.groupData2)
					this.Total1 = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.loading = false
				}
			},

			async getAuthData() {
				//待审核
				try {
					this.loading = true
					let data = {
						IsWaitCheckTab: true,
						Keywords: this.searchKey,
						StartTime: this.startTime,
						EndTime: this.endTime,
						ApplicantType: this.authType,
						WithdrawalsAccountType: this.accountType,
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize
					};
					let result = await WithdrawalList(data);
					this.groupData = result.Result.Results
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.loading = false
				}
			},

			async checkSuccessData() {
				try {
					let data = {
						CommissionWithdrawalInfoList: this.checkSuccessList
					};
					let result = await WithdrawalcheckSuccess();
				} catch (e) {
					//TODO handle the exception
				}
			},

			doorAdd() {},
			goodsSearch() {
				this.currentPage = 1
				this.getAuthData();
			},
			goodsFilter() {
				this.currentPage1 = 1
				this.getDrawData();
			},

			// achievementDetail() {
			// 	this.$router.push({
			// 		path: '/distribution/achievementDetail'
			// 	});
			// },
			eidtGroup(item) {
				this.editVisable = true;
			},
			deleteChose() {},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getAuthData();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getAuthData();
			},
			// 切换显示条数
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val;
				this.getDrawData();
			},

			// 翻页
			handleCurrentChange1(val) {
				this.currentPage1 = val;
				this.getDrawData();
			},
			tabCheckedChange() {
				this.$refs['multipleTable'].toggleAllSelection();
			},
			batchPast(type) {
				this.withDrawType = type
				this.selectionLength = 0
				if (this.multipleSelection.length == 0 && type == 1) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择需要审核通过的提现申请'
					});
					return;
				}
				if (type == 1) {
					this.selectionLength = this.multipleSelection.length
				} else {
					this.groupData.map(record => {
						if (record.WithdrawalState == 0) {
							this.selectionLength++
						}
					})
				}
				if (this.accountType == 0) {
					this.allverifyDialogShow = true;
				} else {
					this.bankbatchPast(type)
				}
			},

			// 银行卡批量审核通过
			async bankbatchPast(type) {
				let message = '您正在操作批量审核通过' + this.selectionLength +
					'条提现申请，提现至<span style="color:red">银行卡</span>。审核通过操作不可撤销，<span style="color:red">请确认您已成功转账</span>。'
				this.$confirm(message, '请注意', {
					confirmButtonText: '确认已转账并审核通过',
					cancelButtonText: '关闭',
					// type: 'warning',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					this.batchSure()
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {})

			},
			async batchSure() {
				try {
					this.loading = true
					this.allverifyDialogShow = false;
					var dataList = [];
					// 批量操作选择的
					if (this.withDrawType == 1) {
						this.multipleSelection.map(item => {
							var obj = {};
							(obj.Id = item.Id), (obj.WithdrawalMoney = item.WithdrawalMoney);
							dataList.push(obj);
						});
					}
					// 批量操作全部待审核的
					if (this.withDrawType == 2) {
						this.groupData.map(record => {
							if (record.WithdrawalState == 0) {
								let objs = {
									Id: record.Id,
									WithdrawalMoney: record.WithdrawalMoney
								}
								dataList.push(objs);
							}
						})
					}

					let data = {
						CommissionWithdrawalInfoList: dataList
					};
					let result = await WithdrawalcheckSuccess(data);
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						})
						this.currentPage = 1
						this.getAuthData()
						this.allFailDialogShow = false
					}
					// this.allFailDialogShow = true

				} catch (e) {
					this.currentPage = 1
					this.getAuthData()
					//TODO handle the exception
				} finally{
					this.loading = false
				}
			},
			//刷新
			refreshData() {
				this.currentPage = 1
				this.getAuthData();
				this.allFailDialogShow = false
			},
			batchRefused(type) {
				this.withDrawType = type
				this.isBatchRefused = true;
				if (this.multipleSelection.length == 0 && type == 1) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择需要批量否决的提现申请'
					});
					return;
				}
				this.ruleForm2.refusedReason = ''
				this.refusedDialogShow = true;
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.groupData.length ? true : false;
				if (this.isCurrentAll) {
					this.checked = true;
				} else {
					this.checked = false;
				}
			},
			async verifySuccess() {
				//单个审核通过
				try {
					this.verifyDialogShow = false;
					this.loading=true
					var dataList = [];
					var obj = {
						Id: this.refusedRow.Id,
						WithdrawalMoney: this.refusedRow.WithdrawalMoney
					};
					dataList.push(obj);

					let data = {
						CommissionWithdrawalInfoList: dataList
					};

					let result = await WithdrawalcheckSuccess(data);

					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						})
						this.currentPage = 1
						this.getAuthData();
					} else {
						// console.log("--------")
						this.failReason = result.Message
						this.failDialogShow = true
					}

				} catch (e) {
					//TODO handle the exception
				} finally{
					this.loading=false
				}
			},
			verifyOk(row) {
				this.refusedRow = row
				//单个审核支付宝
				//是支付宝
				if (row.WithdrawalsAccountType == 0) {
					this.verifyDialogShow = true
				} else {
					this.singleCheckfun(row)
				}

			},

			// 银行卡单个审核			
			singleCheckfun(row) {
				let applayName = row.Name || row.WxNickname
				let message = '您正在操作审核通过' + applayName + '的提现申请，提现金额' + row.WithdrawalMoney +
					'元，提现至<span style="color:red">' + row.WithdrawalsAccountTypeValue +
					'</span>。审核通过操作不可撤销，<span style="color:red">请确认您已成功转账</span>。'

				this.$confirm(message, '请注意', {
					confirmButtonText: '确认已转账并审核通过',
					cancelButtonText: '关闭',
					// type: 'warning',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					this.refusedRow = row;
					this.verifySuccess()
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {})

			},
			verifyRefused(row) {
				this.ruleForm2.refusedReason = ''
				this.isBatchRefused = false;
				this.refusedRow = row;
				this.ruleForm2.refusedReason = ''
				this.refusedDialogShow = true;
			},
			closePop() {
				this.failDialogShow = false;
			},
			authRefused(formName) {
				this.refusedDialogShow = false;
				this.$refs[formName].resetFields();
			},
			authSuccess(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.refusedDialogShow = false;
						this.authRefusedData();
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
				// this.$refs[formName].resetFields();
			},

			async authRefusedData() {
				this.loading = true
				try {
					var dataList = [];
					if (this.isBatchRefused) {
						if (this.withDrawType == 1) {
							this.multipleSelection.map(item => {
								dataList.push(item.Id);
							});
						} else {
							this.groupData.map(record => {
								if (record.WithdrawalState == 0) {
									dataList.push(record.Id);
								}
							})
						}

					} else {
						dataList.push(this.refusedRow.Id);
					}
					let data = {
						IdList: dataList,
						FailReason: this.ruleForm2.refusedReason
					};

					let result = await WithdrawalcheckFail(data);
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '审核否决成功'
						})
					}
					this.getAuthData();
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally{
					this.loading = false
				}
			},
			// 提现中心，页面初始化
			async withdrawalInt() {
				try {
					this.blankLoadings = true
					let result = await commissionWithdrawalInt()
					this.IsOpenBankElementsAttestation = result.Result.IsOpenBankElementsAttestation
					this.IsopenApplyPay = result.Result.IsOpenAliEnterprisePay

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.blankLoadings = false
				}

			},
			changeAccount(row) {
				try {
					this.blankLoadings = true
					//无效 this.IsOpenWithdrawalsToAlipay = JSON.parse(window.localStorage.getItem('IsOpenWithdrawalsToAlipay'))
					//无效 this.IsOpenWithdrawalsToBankCard = JSON.parse(window.localStorage.getItem('IsOpenWithdrawalsToBankCard'))
					this.isShowBankstyle = this.IsOpenWithdrawalsToAlipay && this.IsOpenWithdrawalsToBankCard && (row.MemberId > 0)

					this.MallEmployeeId = row.MallEmployeeId
					this.IsOpenBankElementsAttestation = false
					this.ruleForm.bankName = ''
					this.isredColor = false
					let IsRealNameAuthentication = false
					// 推手
					if (row.MemberId > 0) {
						if (!this.IsOpenWithdrawalsToBankCard && this.IsOpenWithdrawalsToAlipay) {
							this.ruleForm.accountStyle = 0
						}
						if (!this.IsOpenWithdrawalsToAlipay && this.IsOpenWithdrawalsToBankCard) {
							this.ruleForm.accountStyle = 1
						}
						if (this.IsOpenWithdrawalsToAlipay && this.IsOpenWithdrawalsToBankCard) {
							this.ruleForm.accountStyle = row.WithdrawalsAccountType
						}
						// this.withdrawalInt()
					} else {
						this.ruleForm.accountStyle = 0
					}
					// 获取提现人当前账号
					this.getCurrentaccount(row).then(() => {

						this.withdrawalInt().then(() => {

							// 支付宝
							if (row.WithdrawalsAccountType == 0) {
								this.ruleForm.account = row.AlipayAccount
								this.ruleForm.name = row.AlipayName

								this.ruleForm.accountBank = this.bankInformations.BankId ? this.bankInformations.BankId : null
								this.ruleForm.numberBank = this.bankInformations.BankCardNo
								this.ruleForm.bankName = this.bankInformations.BankCardHolder

								IsRealNameAuthentication = this.bankInformations.IsRealNameAuthentication
							} else {
								this.ruleForm.accountBank = row.BankId ? row.BankId : null
								this.ruleForm.numberBank = row.BankCardNo
								this.ruleForm.bankName = row.BankCardHolder

								this.ruleForm.account = this.bankInformations.AlipayAccount
								this.ruleForm.name = this.bankInformations.AlipayName

								IsRealNameAuthentication = row.IsRealNameAuthentication

							}
							this.BankCardHolder = this.ruleForm.bankName
							if (this.IsOpenBankElementsAttestation) {

								if (this.ruleForm.bankName && IsRealNameAuthentication) {
									let bankname = this.ruleForm.bankName + '(只能提现到实名认证' + this.ruleForm.bankName + '的银行卡)'
									// this.ruleForm.bankName = this.ruleForm.bankName + '(只能提现到实名认证' + this.ruleForm.bankName + '的银行卡)'
									this.isredColor = false
									this.$set(this.ruleForm, 'bankName', bankname)
								} else {
									this.isredColor = true
									this.$set(this.ruleForm, 'bankName', '绑定银行卡,用户需先完成实名认证')
									// this.ruleForm.bankName = '绑定银行卡,用户需先完成实名认证'
								}
							}
						}).then(() => {
							setTimeout(() => {
								this.blankLoadings = false
							}, 500)

						})

					})
					this.getBankList()

					this.$forceUpdate()
					this.refusedRow = row;
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					setTimeout(() => {
						this.editInfoDialogShow = true;

					}, 500)

				}

				// this.$nextTick(() => {
				// 	this.$refs['ruleForm'].resetFields();
				// })
			},
			async getCurrentaccount(record) {
				try {
					let data = {}
					if (record.MallEmployeeId > 0) {
						data = {
							MallEmployeeId: record.MallEmployeeId
						}
					}
					if (record.MemberId > 0) {
						data = {
							MemberId: record.MemberId
						}
					}

					let result = await commissionWithdrawalAccount(data)
					this.bankInformations = result.Result

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {

				}

			},
			inforCloseFun() {
				this.$nextTick(() => {
					this.$refs['ruleForm'].clearValidate();
				});
			},
			styleChange() {
				this.$nextTick(() => {
					this.$refs['ruleForm'].clearValidate();
				});
			},
			changeAcountSure(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {

						this.acountChangeData(formName);
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},
			acountChangeData(formName) {
				try {
					this.sureEditeing = true
					this.sureHtmls = "银行卡验证中"
					let submitNames = ''
					this.withdrawalInt().then(async () => {
						// 更周到的写法
						// if(this.IsOpenBankElementsAttestation){
						// 	if (this.ruleForm.bankName.indexOf("只能提现到实名认证") != -1) {
						// 		submitNames = this.ruleForm.bankName.split("(")[0];
						// 	}else{
						// 		submitNames = this.ruleForm.bankName
						// 	}
						// }else{
						// 	this.ruleForm.bankName =this.BankCardHolder
						// 	submitNames =this.BankCardHolder
						// 	if(this.ruleForm.accountStyle ==1){								
						// 		if (!submitNames) {
						// 			this.$message({
						// 				showClose: true,
						// 				type: 'error',
						// 				message: '账户姓名不可为空'
						// 			});
						// 			return
						// 		}
						// 	}							
						// }

						// 现在用的
						if (this.ruleForm.bankName.indexOf("只能提现到实名认证") != -1) {
							submitNames = this.ruleForm.bankName.split("(")[0];
						} else {

							if (!this.IsOpenBankElementsAttestation && (this.ruleForm.bankName == '绑定银行卡,用户需先完成实名认证') && this.ruleForm.accountStyle ==
								1) {
								this.ruleForm.bankName = this.BankCardHolder
								submitNames = this.BankCardHolder
								if (!submitNames) {
									this.$message({
										showClose: true,
										type: 'error',
										message: '账户姓名不可为空'
									});
									return
								}
							} else {
								submitNames = this.ruleForm.bankName
							}

						}

						let data = {
							Id: this.refusedRow.Id,
							AlipayAccount: this.ruleForm.account,
							AlipayName: this.ruleForm.name,
							WithdrawalsAccountType: this.ruleForm.accountStyle,
							BankId: this.ruleForm.accountBank,
							BankCardNo: this.ruleForm.numberBank,
							BankCardHolder: submitNames
						};

						let res = await WithdrawalupdateAccount(data);
						if (res.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功'
							});
							this.editInfoDialogShow = false;
							this.getAuthData()
							this.$refs[formName].resetFields();
							setTimeout(() => {
								this.sureHtmls = '确认修改'
								this.sureEditeing = false
							}, 1000)
						}

					})

				} catch (e) {
					//TODO handle the exception
					console.log(e)
					setTimeout(() => {
						this.sureHtmls = '确认修改'
						this.sureEditeing = false
					}, 1000)

				} finally {}
			}
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.startTime;
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(limitTime);
						}
					}
				};
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime;
				return {
					disabledDate(time) {
						return (
							time > new Date(limitTime)
							// ||time < Date.now()
						);
					}
				};
			},
			//日期选择器限制选择
			minOptions2: function() {
				let limitTime = this.startTime2;
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(limitTime);
						}
					}
				};
			},
			//日期选择器限制选择
			maxOptions2: function() {
				let limitTime = this.endTime2;
				return {
					disabledDate(time) {
						return (
							time > new Date(limitTime)
						);
					}
				};
			}
		}
	};
</script>

<style lang="less" scoped>
	.widthDrawal-box {

		//新增样式
		.displayCommon {
			display: -webkit-box;
			/* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
			display: -moz-box;
			/* Firefox 17- */
			display: -webkit-flex;
			/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
			display: -moz-flex;
			/* Firefox 18+ */
			display: -ms-flexbox;
			/* IE 10 */
			display: flex;
			/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
		}

		.warn_Content {
			background: #FDF6EC;
			border: 1px solid #FDA23C;
			padding: 15px 0 15px 10px;
			font-size: 13px;
			color: #FDA23C;
			margin-bottom: 10px;
		}

		//新增样式结束
		.batctClassbtn {
			margin-bottom: 15px;
		}

		.nameRedcolor {
			input {
				color: red !important;
			}

		}

	}
</style>
